import React from 'react';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import { CallToActionPropTypes } from './CallToAction.types';
const CallToAction: React.FC<CallToActionPropTypes> = ({
  entryId = '',
  attr,
  title,
  bodyCopy,
  buttons,
  className,
  id
}) => {
  return <Container id={id} attr={attr} className='!max-w-[118.4rem] rounded-[2.2rem] bg-background-tertiary px-[2rem] py-[4rem] md:px-[3rem] md:py-[8rem]' data-sentry-element="Container" data-sentry-component="CallToAction" data-sentry-source-file="CallToAction.tsx">
      <SectionHeader entryId={entryId} title={title} titleClassName='w-[100%] text-header-copy font-normal text-left sm:text-center leading-[2.2rem] !font-[200] text-[2.2rem] md:text-[2.8rem] lg:leading-[4.8rem] lg:text-[4.8rem] mb-[3.2rem]' data-sentry-element="SectionHeader" data-sentry-source-file="CallToAction.tsx" />
      <RenderBodyCopy entryId={entryId} className={`${className} mx-auto mb-[2.2rem] max-w-[82rem] text-pretty text-left font-[200] sm:text-center md:mb-[2.4rem] lg:mb-[3.2rem] lg:[&>p]:!text-[2.4rem]`} bodyCopy={bodyCopy} data-sentry-element="RenderBodyCopy" data-sentry-source-file="CallToAction.tsx" />
      <RenderButtons className='justify-center' items={buttons} data-sentry-element="RenderButtons" data-sentry-source-file="CallToAction.tsx" />
    </Container>;
};
export default CallToAction;