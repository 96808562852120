import React from 'react';

export type SectionHeaderPropTypes = {
  entryId?: string;
  pretitle?: string;
  title: string;
  id?: string | undefined;
  className?: string;
  preTitleClassName?: string;
  titleClassName?: string;
  childrenClassName?: string;
  align?: 'left' | 'center';
  children?: React.ReactNode;
  pretitleRef?: React.RefObject<HTMLHeadingElement> | undefined;
  titleRef?: React.RefObject<HTMLHeadingElement> | undefined;
  paragraphRef?: React.RefObject<HTMLParagraphElement> | undefined;
  slideTextRef?: React.RefObject<HTMLParagraphElement> | undefined;
  titleHighlightBold?: boolean;
  headingLevel?: HeadingTagTypes;
};

export const HeaderMappingTypes = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
} as const;

export type HeadingTagTypes = keyof typeof HeaderMappingTypes;
