'use client';

import { useContentfulLiveUpdates } from '@contentful/live-preview/react';

interface UsePreviewContentParams<T> {
  content: T;
}

/**
 * Hook to manage live updates for Contentful entries in non-production environments.
 * @param params - Object containing the content to enable live updates for.
 * @returns Updated content in preview mode; original content in production.
 */
const usePreviewContent = <T extends { sys: { id: string }; __typename?: string }>({
  content,
}: UsePreviewContentParams<T>): T => {
  const liveContent = useContentfulLiveUpdates(content);

  return process.env.NEXT_PUBLIC_ENABLE_LIVE_PREVIEW !== 'live' ? liveContent : content;
};

export default usePreviewContent;
