import React from 'react';
import Container from '@src/components/Shared/Container';
import InlineSVG from '@src/components/Shared/InlineSVG';
import NextImage from '@src/components/Shared/NextImage';
import { HeroValdProdPropTypes } from './HeroValdProd.types';
const HeroValdProd: React.FC<HeroValdProdPropTypes> = ({
  ctfData,
  id,
  wrapClasses,
  logoClassName,
  imgClassName,
  introClassName,
  headerClassName
}) => {
  return <Container attr='vald-hero-prod-component' id={id} className={`relative mt-[6.5rem] flex flex-col md:min-h-[50rem] md:flex-row md:items-center md:justify-between ${wrapClasses}`} data-sentry-element="Container" data-sentry-component="HeroValdProd" data-sentry-source-file="HeroValdProd.tsx">
      <header className={headerClassName}>
        {ctfData?.heroLogo?.url && <InlineSVG className={`mb-[2.4rem] mt-[3rem] max-w-[27.6386rem] xsm:min-h-[5.3219rem] md:mt-[4.1rem] md:min-h-[4.9484rem] md:max-w-[25.7rem] lg:min-h-[6.9188rem] lg:max-w-[35.9302rem] 2xl:min-h-[10.3906rem] 2xl:max-w-[53.96rem] ${logoClassName}`} src={ctfData?.heroLogo?.url} />}

        <p className={introClassName}>
          {ctfData?.bodyCopy?.json?.content[0]?.content.map((p: any) => {
          if (p.marks[0]?.type === 'bold') {
            return <strong key={p.value} className='font-[500] text-primary'>
                  {p.value}
                </strong>;
          }
          return p.value;
        })}
        </p>
      </header>

      <NextImage className={`${imgClassName}`} priority={true} src={ctfData?.heroImage?.url} alt={ctfData?.heroImage?.description ?? '...'} aspectRatio={ctfData?.heroImage?.width / ctfData?.heroImage?.height} width={ctfData?.heroImage?.width} data-sentry-element="NextImage" data-sentry-source-file="HeroValdProd.tsx" />
    </Container>;
};
export default HeroValdProd;